import * as React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import { classNames } from "../utilities"

// Create URL path for numeric pagination
const getPageNumberPath = (currentIndex, basePath) => {
  if (currentIndex === 1) {
    return basePath
  }

  return `${basePath}/${currentIndex}`
}

// Create an object array of pagination numbers.
// The number of page numbers to render is set to 5.
const getPaginationGroup = (basePath, c, m) => {
  const current = c,
    last = m,
    delta = 2,
    left = current - delta,
    right = current + delta + 1,
    range = [],
    rangeWithDots = []

  for (let i = 1; i <= last; i++) {
    if (i === 1 || i === last || (i >= left && i < right)) {
      range.push(i)
    }
  }

  let l = 0
  for (let i of range) {
    if (l) {
      if (i - l === 2) {
        const number = l + 1
        rangeWithDots.push({
          number,
          url: getPageNumberPath(number, basePath),
          isCurrent: current === number,
        })
      } else if (i - l !== 1) {
        rangeWithDots.push(null)
      }
    }
    rangeWithDots.push({
      number: i,
      url: getPageNumberPath(i, basePath),
      isCurrent: current === i,
    })
    l = i
  }

  return rangeWithDots
}

const BasePagination = ({ pageInfo, basePath }) => {
  if (!pageInfo) return null

  const { humanPageNumber: currentPage, numberOfPages: pageCount } = pageInfo
  const prevPagePath =
    currentPage === 2 ? basePath : `${basePath}/${currentPage - 1}`
  const nextPagePath = `${basePath}/${currentPage + 1}`

  const btnClass =
    "group inline-flex h-10 w-10 items-center justify-center rounded-full p-2 border border-brand-primary bg-white transition-color duration-150"

  if (pageCount > 1) {
    return (
      <div className="flex-center flex justify-center font-bold">
        <ol className="flex items-center gap-4">
          {currentPage > 1 ? (
            <li>
              <Link
                to={prevPagePath}
                className={classNames(btnClass, " hover:bg-brand-primary")}
              >
                <svg
                  className="rotate-180 fill-brand-primary group-hover:fill-white"
                  width={13}
                  height={12}
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="m6.38 11.636 5.727-5.727L6.38.182 5.062 1.494l3.46 3.455H.414v1.92h8.108l-3.46 3.46 1.318 1.307Z" />
                </svg>
              </Link>
            </li>
          ) : null}
          {getPaginationGroup(basePath, currentPage, pageCount).map(
            (item, i) => {
              return item ? (
                <li key={i}>
                  <Link
                    to={item.url}
                    className={classNames(
                      btnClass,
                      `${
                        item.isCurrent
                          ? "border-black bg-black text-black"
                          : "hover:border-black hover:bg-black hover:text-white"
                      }`
                    )}
                  >
                    {item.number}
                  </Link>
                </li>
              ) : (
                <li>
                  <div>...</div>
                </li>
              )
            }
          )}
          {currentPage !== pageCount ? (
            <li>
              <Link
                to={nextPagePath}
                className={classNames(btnClass, " hover:bg-brand-primary")}
              >
                <svg
                  className="fill-brand-primary group-hover:fill-white"
                  width={13}
                  height={12}
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="m6.38 11.636 5.727-5.727L6.38.182 5.062 1.494l3.46 3.455H.414v1.92h8.108l-3.46 3.46 1.318 1.307Z" />
                </svg>
              </Link>
            </li>
          ) : null}
        </ol>
      </div>
    )
  } else {
    return null
  }
}

BasePagination.propTypes = {
  pageInfo: PropTypes.object,
  basePath: PropTypes.string,
}

export default BasePagination
