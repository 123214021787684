import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import BaseSection from "../components/base-section"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import BaseSeparator from "../components/base-separator"
import BaseHeader from "../components/base-header"
import LogoCard from "../components/logo-card"
import BaseBtn from "../components/base-btn"
import { useDownloadImages } from "../hooks/useDownloadImages"
import PressArticlesPreviewGrid from "../components/blog/press-articles-preview-grid"
import BasePagination from "../components/base-pagination"
import { byArticlesWithNoTestSlug } from "../utilities"

const PrensaTemplate = ({ data, pageContext }) => {
  const SEO = {
    metaTitle: "Zona de Prensa - Voltio",
    metaDescription:
      "¿Quieres conocer qué noticias ocurren en Voltio? Encuentra aquí todas nuestras notas de prensa, noticias y material.",
  }
  const { downloadImages } = useDownloadImages()
  const firstCover = data.allStrapiPress.nodes?.[0]?.cover || null
  const restArticles = data.allStrapiPress.nodes.filter(
    byArticlesWithNoTestSlug
  )

  return (
    <Layout>
      <Seo seo={SEO} />

      <BaseHeader
        title="Las últimas noticias de Voltio"
        image={
          <GatsbyImage
            className="w-full flex-shrink-0 rounded-sm"
            image={getImage(firstCover?.localFile)}
            alt={firstCover?.alternativeText || ""}
          />
        }
        titleLg={true}
      />

      <BaseSection>
        <div className="grid grid-cols-3 md:mx-14">
          <div className="col-span-3 md:col-span-3 lg:col-span-2">
            <h2 className="voltio-subtitle">Lo más Voltio</h2>
          </div>
          <div className="col-span-3 font-medium md:col-span-3 md:ml-0 md:mt-10 lg:col-span-1 lg:ml-14 lg:mt-0">
            Conoce todo lo que ocurre en Voltio, todas nuestras acciones y
            logros más recientes. Si tienes una consulta específica,{" "}
            <Link to="/contacto" className="text-brand-primary">
              ¡contáctanos!
            </Link>
          </div>
        </div>
      </BaseSection>

      <BaseSection>
        <PressArticlesPreviewGrid articles={restArticles} />

        <div className="mt-20">
          <BasePagination basePath="/blog" pageInfo={pageContext} />
        </div>
      </BaseSection>

      <BaseSeparator />

      <BaseSection>
        <div className="grid grid-cols-3 md:mx-14">
          <div className="col-span-3 md:col-span-3 lg:col-span-2">
            <h2 className="voltio-subtitle">
              Identidad de <br />
              marca
            </h2>
          </div>
          <div className="col-span-3 font-medium md:col-span-3 md:ml-0 md:mt-10 lg:col-span-1 lg:ml-14 lg:mt-0">
            Si prefieres, puedes descargar toda la identidad con un sólo click.
            <BaseBtn
              onClick={() => downloadImages()}
              className="mt-6 sm:w-full"
            >
              Descargar todas las imágenes
            </BaseBtn>
          </div>
          <div className="col-span-3 mt-14 grid grid-cols-1 gap-12 md:grid-cols-2 lg:grid-cols-3">
            <LogoCard
              downloadable
              image={
                <StaticImage
                  className="rounded-sm"
                  placeholder="blurred"
                  alt="Logo Voltio"
                  layout="constrained"
                  src="../assets/imgs/id_marca.png"
                  height={100}
                />
              }
            />
            <LogoCard
              downloadable
              image={
                <StaticImage
                  className="rounded-sm"
                  placeholder="blurred"
                  alt="Logo Voltio"
                  layout="constrained"
                  src="../assets/imgs/Voltio-horizontal-rgb.png"
                  height={100}
                />
              }
            />
          </div>
        </div>
      </BaseSection>
    </Layout>
  )
}

export default PrensaTemplate

export const pageQuery = graphql`
  query ($skip: Int!, $limit: Int!) {
    allStrapiPress(
      skip: $skip
      limit: $limit
      sort: { fields: [createdAt], order: DESC }
      filter: { locale: { eq: "es-ES" } }
    ) {
      nodes {
        ...PressArticlesPreviewCard
      }
    }
  }
`
